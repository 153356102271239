


















































































import Vue from 'vue';
import { setQueryAllAttribute } from '@/utils/DomUtil';
import { Component, Watch } from 'vue-property-decorator';
import ResetPasswordApi from '@/services/api/ResetPassword';

@Component
class ChangePasswordComponent extends Vue {
    public pwShow1: boolean = false;
    public pwShow2: boolean = false;
    public pwShow3: boolean = false;
    public newPassword: string = '';
    public oldPassword: string = '';
    public passwordVerify: string = '';
    public valid: boolean = true;
    public message: string = '';
    public showProgressBar: boolean = false;
    public progressBarMessage: string = '';
    public verifyErrorMessages: object = [];
    public readonly successMessage: string = 'Password has been successfully changed';
    public readonly serverErrorMessage: string = 'Server error';

    public rules = {
        required: (value: string) => !!value || 'required field',
        password: (value: string) => value.trim().length >= 8 || 'At least 8 characters',
        whitespace: (value: string) => {
            const pattern = new RegExp(/^(\S+\s*\S+)+$/);
            return pattern.test(value) || 'Password should not contain leading or trailing whitespace';
        },
    };

    public mounted() {
        setQueryAllAttribute('.v-icon', 'tabindex', '-1');
    }

    @Watch('newPassword')
    public onNewPasswordChanged() {
        this.passwordMatch();
    }

    @Watch('passwordVerify')
    public onPasswordVerifyChanged() {
        this.passwordMatch();
        this.addMessageOnEmptyVerifyPassword();
    }

    public addMessageOnEmptyVerifyPassword() {
        if (this.passwordVerify.length === 0) {
            this.verifyErrorMessages = ['required field'];
        }
    }

    public passwordMatch() {
        if (this.passwordVerify.length > 0) {
            if ( this.newPassword !== this.passwordVerify) {
                this.verifyErrorMessages = ['Passwords you entered don\'t match'];
                this.valid = false;
            } else {
                this.verifyErrorMessages = [];
            }
        }
    }
    public submit(oldPassword: string, newPassword: string): void {
        if ((this.$refs.form as any).validate() && this.valid) {
            this.$store.commit('notificationState/setSpinnerMessage', 'Updating profile...');
            ResetPasswordApi.changePassword({old_password : oldPassword, new_password: newPassword})
                .then(response => {
                    this.$store.commit('notificationState/clearSpinnerMessage');
                    if (response.data.success) {
                        this.$store.commit('notificationState/setSuccessMessage', this.successMessage);
                        this.$router.push({name: 'profile'});
                    } else {
                        this.$store.commit('notificationState/setErrorMessage', response.data.error);
                    }
                })
                .catch(() => {
                    this.$store.commit('notificationState/clearSpinnerMessage');
                    this.$store.commit('notificationState/setErrorMessage', this.serverErrorMessage);
                });
        }
    }
}

export default ChangePasswordComponent;
